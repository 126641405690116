<template>
  <div id="Viracloudlist">
    <vs-table

      v-model="selected"
      :data="ViraClist"
    >
      <template slot="thead">
        <vs-th> VM name  </vs-th>
        <vs-th> VM Status </vs-th>
        <vs-th> user ID </vs-th>
        <vs-th> Vm ID </vs-th>
        <vs-th> Node ID </vs-th>
        <vs-th> Location </vs-th>
        <vs-th> Vm number </vs-th>
        <vs-th> Public IP </vs-th>
        <vs-th> Suspend </vs-th>
        <vs-th> Suspend At </vs-th>
        <vs-th> Created At </vs-th>
        <vs-th></vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].vm_title">
            {{ data[indextr].vm_title }}
          </vs-td>
          <vs-td :data="data[indextr].vm_status">
            <span v-if="data[indextr].vm_status == 1"
              ><p class="text-warning">
                {{ data[indextr].vm_status }}
              </p>
              در حال ساخت</span
            >
            <span v-if="data[indextr].vm_status == 2"
              ><p class="text-warning">
                {{ data[indextr].vm_status }}
              </p>
              درحال ساخت</span
            >
            <span v-if="data[indextr].vm_status == 3"
              ><p class="text-warning">
                {{ data[indextr].vm_status }}
              </p>
              درحال ساخت</span
            >
            <span v-if="data[indextr].vm_status == 4"
              ><p class="text-success">
                {{ data[indextr].vm_status }}
              </p>
              آنلاین</span
            >
            <span v-if="data[indextr].vm_status == 6"
              ><p class="text-danger">
                {{ data[indextr].vm_status }}
              </p>
              خاموش</span
            >
          </vs-td>
          <vs-td :data="data[indextr].vm_id">
            {{ data[indextr].user_id }}
          </vs-td>
          <vs-td :data="data[indextr].vm_id">
            {{ data[indextr].id }}
          </vs-td>
          <vs-td :data="data[indextr].node_id">
            {{ data[indextr].node_id }}
          </vs-td>
          <vs-td :data="data[indextr].location.country_iso">
            {{ data[indextr].location.country_iso }}
          </vs-td>
          <vs-td @click="copyvalue('sdf')" :data="data[indextr].vm_sequence_id">
            {{ data[indextr].vm_sequence_id }}
          </vs-td>
          <vs-td :data="data[indextr].subject">
            {{ data[indextr].vm_master_ip ? data[indextr].vm_master_ip : "none" }}
          </vs-td>
          <vs-td
            :class="data[indextr].is_suspend ? 'text-danger' : 'text-success'"
            :data="data[indextr].subject"
          >
            {{ data[indextr].is_suspend ? "متوقف شده" : "متوقف نشده" }}
          </vs-td>
          <vs-td :data="data[indextr].subject">
            {{ data[indextr].suspended_at || "---------" }}
          </vs-td>
          <vs-td :data="data[indextr].subject">
            {{ data[indextr].created_at }}
          </vs-td>
          <vs-td>
            <vs-button
              size="small"
              icon="..."
              href="#detail"
            ></vs-button>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      class="my-5"
      :total="Vira_LP"
      v-model="Vira_PN"
    ></vs-pagination>

    <vue-json-pretty :data="selected" id="detail"></vue-json-pretty>
  </div>
</template>
<style>
[dir=rtl] .vjs-tree {
    text-align: left !important;
    direction: ltr;
}
</style>
<script>
  import VueJsonPretty from "vue-json-pretty";
  import "vue-json-pretty/lib/styles.css";
export default {
  components: {
    VueJsonPretty,
  },
  props: {
    userId: { required: true },
  },
  data() {
    return {
      ViraClist: [],
      selected: "",
      //===PAGINATION===
      Vira_LP: 1,
      Vira_PN: 1,
      //===PAGINATION===

      // POPUPS=========
      moreViraDataPopup: false,
      // POPUPS=========
    };
  },
  watch: {
    selected() {
      this.$emit("selected", this.selected);
    },
  },
  methods: {
    getViracloudlist() {
      this.$http
        .get(`admin/cloud/vm?user_id=${this.$route.query.id || this.userId}`)
        .then((res) => {
          this.Vira_LP = res.data.params.last_page;
          this.ViraClist = res.data.params.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    doubleSelection() {
      this.$router.push(`/user?id=${this.selected.user_id}`);
    },
  },
  created() {
    this.getViracloudlist();
  },
};
</script>

<style>
.tr-table-state-primary, .vs-table-primary .is-selected {
  color: #fff;
}
</style>
